//
// dropdowns.scss
// Extended from Bootstrap
//

//
// Bootstrap overrides ==================================
//

// Dropdown arrows
//
// Replace original arrows with Feather icons

.custom-dropdown {
  $grey-100: #e8e8e8;
  $grey-200: #7e7e7e;
  $black: #000000;
  $pink-100: #fff2f2;
  $font-size-sm: 0.9375rem;
  $font-size: 1rem;

  color: $grey-200;
  font-size: $font-size-sm;

  &:hover {
    color: $black;
    background-color: $pink-100 !important;
  }

  .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .25rem;
    padding: .25rem .5rem;

    &:after {
      content: "\25be";
      margin: 0;
    }
  }

  .dropdown-menu {
    padding: 0;
    box-shadow: 0 0 0 0.05rem $grey-100;

    .dropdown-item {
      font-size: $font-size;
      color: $black;

      &:hover {
        background-color: $grey-100;
      }
    }
  }

  .dropdown-button {
    border: 1px solid $metallic-gray;
    box-sizing: border-box;
    border-radius: $border-radius-sm;
    background: transparent;
    outline: none;
  }

  .material-icons, .material-symbols-outlined {
    font-size: $font-size;
    font-weight: 500;
  }
}

.dropdown-toggle {

  &:after {
    content: '\e92e';
    width: auto; height: auto;
    border: none !important;
    vertical-align: middle;
    font-family: 'Feather';
  }
}

.dropup > .dropdown-toggle {

  &:after {
    content: '\e931';
  }
}

.dropright > .dropdown-toggle {

  &:after {
    content: '\e930';
  }
}

.dropleft > .dropdown-toggle {

  &:after {
    content: '\e92f';
  }
}


// Dropdown toggle
//
// Right align arrows

.dropdown-item.dropdown-toggle {
  display: flex;
  justify-content: space-between;
}


// Dropdown menu animation
//
// Animate dropdown menu appearance

.dropdown-menu {
  animation: dropdownMenu .15s;
}

@keyframes dropdownMenu {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



//
// Dashkit ===================================
//


// Dropdown card
//
// Makes the dropdown menu act like a card

.dropdown-menu-card {
  min-width: $dropdown-card-min-width;
  padding-top: 0;
  padding-bottom: 0;
  background-color: $card-bg;
  border-color: $dropdown-card-border-color;

  .card-header {
    min-height: $dropdown-card-header-min-height;
  }

  .card-body {
    max-height: $dropdown-card-body-max-height;
    overflow-y: auto;
  }
}
